import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledFieldset = styled.fieldset`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid silver;
  padding: 1em;
`

interface FieldsetProps {
  className?: string;
}

const Fieldset: React.SFC<FieldsetProps> = ({ children, className }) => (
  <StyledFieldset className={className}>{children}</StyledFieldset>
)

export default Fieldset
