import * as React from 'react'
import {EditFormBase} from '../forms';
import Page from '../components/Page'
import IndexLayout from '../layouts'
import {Col} from "../components/Col";
import {Row} from "../components/Row";
import * as Api from '../api/api'
import Fieldset from "../components/Fieldset";
import {navigate} from 'gatsby';

class IndexPage extends React.Component<any, {accountNumber: string, accountName: string, success: boolean, submitted: boolean, isLoading: boolean, editingRecord: Api.DirectAlertTableEntity | null}> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			accountNumber: (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('accountNumber')) || '',
			accountName: '',
			submitted: false,
			isLoading: false,
			success: false,
			editingRecord: null,
		};
		console.log(props);
	}

	public handleForm(e) {
		e.preventDefault();
		this.setState({isLoading: true, submitted: true}, () => {
			new Api.DirectAlertApi().validateRegistrationPOST({
				req: {
					accountName: this.state.accountName,
					accountNumber: this.state.accountNumber,
				}
			}).then((res) => {
				if (res.success && res.value.registrations && res.value.registrations.length > 0) {
					sessionStorage.setItem('editingItem', JSON.stringify(res.value.registrations[0]));
					navigate('/update');
					// location.href = '/update';
				} else {
					this.setState({success: false, isLoading: false});
				}
			}, (err) => {
				console.log('Error', err);
				this.setState({success: false, isLoading: false});
			})
		});
	}

	public handleAccount(accountNumber: string) {
		this.setState({accountNumber});
		sessionStorage.setItem('accountNumber', accountNumber);
	}

	public renderForm() {
		return (
			<Fieldset>
				<h2>Validation Information</h2>
				<p>
					The following information is required for confirmation. We'll check that this
					information matches our records and if it does, we'll proceed with the information update.
				</p>

				{!this.state.isLoading && this.state.submitted && !this.state.success && <div style={{color: 'maroon', fontWeight: 'bold'}}>
					Couldn't verify your information. Please check your information and submit again.
				</div>}

				<form onSubmit={(e) => this.handleForm(e)}>
					<Row>
						<Col sm={5}>Account Number</Col>
						<Col sm={7}>
						{EditFormBase.boundTextboxValue(
							null,
							this.state.accountNumber,
							(accountNumber) => this.handleAccount(accountNumber),
							'',
							false,
							this.state.isLoading)
						}
						</Col>
					</Row>
					<Row>
						<Col sm={5}>Last Name on Account</Col>
						<Col sm={7}>
						{EditFormBase.boundTextboxValue(
							null,
							this.state.accountName,
							(e) => this.setState({accountName: e.toUpperCase()}),
							'',
							false,
							this.state.isLoading)
						}
						</Col>
					</Row>
					<Row>
						<Col sm={5} />
						<Col sm={7}>
						<button className="btn btn-primary" type="submit" disabled={this.state.isLoading}>
							<i className="icon-white icon-check" />
							{this.state.isLoading ? 'Please wait...' : 'Verify'}
						</button>
						</Col>
					</Row>
					<p>This page is secure.</p>
				</form>
			</Fieldset>
		);
	}

	public render() {
		return this.renderMain();
	}

	public renderMain() {
		return  (
			<IndexLayout>
				<Page>
					<Row>
						<Col sm={4}>
							{this.renderForm()}
              <div style={{paddingTop: '10px'}}>
                By providing your number you agree to allow Middlesex Water Company to uses the telephone number you provide to communicate with you about your water utility services, service interruptions, boil water advisories and other aspects of services we provide.  Middlesex Water does not engage in telemarketing and all communication with you via telephone is conducted in accordance with all applicable state laws. We encourage you to review our privacy policy located here <a href={"https://www.middlesexwater.com/privacy-policy/"}>https://www.middlesexwater.com/privacy-policy/</a>.
              </div>
						</Col>
					</Row>
				</Page>
			</IndexLayout>
		);
	}
}

export default IndexPage
